<template>
  <div class="retrieve">
    <div class="content">
      <div class="title">
        <h3>兰州市残疾人就业创业网络服务平台 | 账号安全</h3>
        <router-link to="/">返回首页</router-link>
      </div>
      <div class="tab">
        <h1>通过以下流程找回密码</h1>
        <ul class="ul">
          <li class="active">
            <img src="~/static/login/01_active.png" alt="">
            <h3>通过以下流程找回密码</h3>
          </li>
          <li class="active">
            <img src="~/static/login/02_active.png" alt="">
            <h3>验证信息</h3>
          </li>
          <li :class="active == 1?'active':''">
            <img :src="active == 1?require('@/static/login/03_active.png'):require('@/static/login/03.png')" alt="">
            <h3>重置密码</h3>
          </li>
        </ul>
      </div>
      <el-form :model="form" :rules="error" label-width="120px" ref="forms" class="form" :hide-required-asterisk="true"
               v-if="active == 0">
        <el-form-item label="手机号码" prop="iphone">
          <el-input v-model="form.iphone" placeholder="请输入已绑定的手机号码" class="wrap_input w336"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="iphoneCode">
          <el-input
              class="w336 wrap_input"
              v-model="form.iphoneCode"
              placeholder="请输入短信验证码"
              onkeyup="this.value=this.value.replace(/^ +| +$/g,'')"
          >
            <template slot="append">
              <el-button
                  @click="sendCheckCode"
                  class="sendcode"
                  :class="{ 'disabled-style': getCodeBtnDisable }"
                  :disabled="getCodeBtnDisable"
              >{{ btntxt }}
              </el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item class="btn">
          <el-button type="primary" plain class="w180" @click="goRouter">上一步</el-button>
          <el-button type="primary" class="w180" @click="nextSubmit">下一步</el-button>
        </el-form-item>
      </el-form>
      <el-form :model="forms" :rules="errForms" label-width="120px" ref="form" class="form"
               :hide-required-asterisk="true" v-if="active == 1">
        <el-form-item label="设置密码" prop="pass">
          <el-input v-model="forms.pass" type="password" placeholder="请设置新密码(密码必须为8位以上，且必须包含数字、字母和特殊字符)" class="wrap_input w336"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="newPass">
          <el-input v-model="forms.newPass" type="password" placeholder="请再次输入密码(密码必须为8位以上，且必须包含数字、字母和特殊字符)" class="wrap_input w336"></el-input>
        </el-form-item>
        <el-form-item class="btn">
          <el-button type="primary" plain class="w180" @click="active = 0">上一步</el-button>
          <el-button type="primary" class="w180" @click="submit">立即提交</el-button>
        </el-form-item>
      </el-form>
    </div>
    <dialogImageCode ref="dialogImageCode" @changeCode="sendMsg"/>
  </div>
</template>

<script>
import dialogImageCode from "components/login/dialogImageCode";
export default {
  name: "phone",
  components:{dialogImageCode},
  data() {
    return {
      form: {
        iphone: '',
        iphoneCode: '',
      },
      error: {
        iphone: [
          {
            required: true,
            message: "请输入您的手机号码",
            trigger: "blur",
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        iphoneCode: [
          {required: true, message: "请输入收到的验证码", trigger: "blur"},
        ],
      },
      forms: {
        pass: '',
        newPass: '',
      },
      errForms: {
        pass: [
          { required: true, message: "您设置的密码有误！", trigger: "blur" },
          {
            pattern: /^[a-zA-Z\s\d\.~!@#$%\^&\*\(\)-_=+\\\{\}\]:;"',/\?]{8,}$/,
            message:
                "密码必须为8位以上，且必须包含数字、字母、特殊字符",
            trigger: "blur",
          },
        ],
        newPass: [
          { required: true, message: "您设置的密码有误！", trigger: "blur" },
          {
            pattern: /^[a-zA-Z\s\d\.~!@#$%\^&\*\(\)-_=+\\\{\}\]:;"',/\?]{8,}$/,
            message:
                "密码必须为8位以上，且必须包含数字、字母、特殊字符",
            trigger: "blur",
          },
        ],
      },
      btntxt: "获取验证码",
      time: 180,
      getCodeBtnDisable: false,
      active: 0,
    }
  },
  methods: {
    nextSubmit() {
      this.$refs.forms.validate(async (valid) => {
        if (!valid) {
          this.$message.error("信息填写不完整");
        } else {
          this.active = 1;
        }
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.forms.newPass != this.forms.pass) {
            this.$message({
              message: "两次密码不一致，请重新输入",
              center: true,
              type: "warning",
            });
            return
          } else {
            let that = this;
            let params = {
              pwd: this.$sm2Encrypt(this.forms.newPass),
              iphone: this.form.iphone,
              iphoneCode: this.form.iphoneCode,
            };
            that.$api.findPwd(params).then(function (res) {
              if (!res.data.success) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message({
                  message: "密码修改成功",
                  center: true,
                  type: "success",
                });
                that.$router.push("/");
              }
            });
          }
        } else {
          this.$message.error("信息填写不完整");
        }
      })
    },
    sendCheckCode() {
      let iphone = /^1[3456789]\d{9}$/;
      if (iphone.test(this.form.iphone)) {
       this.$refs.dialogImageCode.open();
      } else {
        this.$message.error("手机号码不正确");
      }
    },
    // 单独领出来的短信发送接口
    sendMsg(val) {
      this.getCodeBtnDisable = true;
      this.btntxt = '发送中...';
      this.$axios
          .post(
              "/api/app-jycy-phoneverificationcode/verification",
              {
                phone:this.form.iphone,
                uuid:val.uuid,
                code:val.code
              }
          ).then(res => {
        if (res.data.success) {
          this.$message.success({
            message: "验证码已经发送至您的手机，请注意接收",
          });
          this.time = 180;
          this.timer();
        } else {
          this.getCodeBtnDisable = false;
          this.btntxt = '获取验证码';
          this.$message.error({
            message: res.data.msg || "发送失败",
          });
        }
      })
      this.$refs.dialogImageCode.close();
    },
    //倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.getCodeBtnDisable = false;
      }
    },
    goRouter() {
      this.$router.push('/retrievePassword')
    },
  }
}
</script>

<style scoped lang="less">
.retrieve {
  width: 100%;
  min-height: 100vh;
  background: white;

  .w336 {
    width: 336px;
  }

  .content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 100px;

    .title {
      display: flex;
      padding-top: 26px;

      h3 {
        flex: 1;
        font-size: 18px;
        color: #28A46D;
      }

      a {
        font-size: 18px;
        color: #66716C;
      }

      a:hover {
        color: #28A46D;
      }
    }

    .tab {
      width: 635px;
      margin: 0 auto;
      padding-top: 60px;

      h1 {
        font-size: 28px;
        padding-bottom: 36px;
        text-align: center;
      }

      .ul {
        display: flex;

        li {
          position: relative;
          flex: 1;
          text-align: center;

          h3 {
            font-size: 18px;
            margin-top: 6px;
            color: #66716c;
          }
        }

        li.active h3 {
          color: #28A46D;
        }

        li::after {
          content: '';
          position: absolute;
          top: 50%;
          left: -50px;
          transform: translateY(-50%);
          width: 102px;
          height: 2px;
          background: #CCD0CE;
        }

        li.active::after {
          background: #28A46D;
        }

        li:first-child::after {
          display: none;
        }
      }
    }

    /deep/ .form {
      width: 792px;
      margin: 26px auto 0 auto;
      padding: 36px 109px;

      .el-form-item {
        margin-bottom: 36px;

        .el-input__inner::placeholder {
          font-size: 14px;
          color: #99A09D;
        }

        .el-input-group--append {
          border: 1px solid #CECFD0;
          border-radius: 6px;

          .el-input__inner {
            border: none;
          }
        }

        .el-input-group__append {
          border: none;
          padding: 0 10px;
          background: rgba(0, 0, 0, 0.03);

          span {
            display: inline-block;
            font-size: 14px;
            color: #66716C;
            padding-left: 20px;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
          }

          .el-button.is-disabled, .el-button.is-disabled:hover, .el-button.is-disabled:focus {
            border: none;
            background: none;
            color: #00924b;
          }
        }
      }

      .btn {
        margin-top: 126px;
        transform: translateX(-24px);

        .el-button {
          margin-right: 24px;
        }
      }
    }
  }
}
</style>
